import React, { useEffect } from 'react'
// import Cards from '../../Component/Product/Cards'
import Header from '../../Component/Header'
import DaynamicProducts from '../../Component/DaynamicProducts/DaynamicProducts'

const Product = () => {
    
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); 
    
    return (
        <>
            <Header name="Product" image="/Images/709A0038.jpg"/>
            <div className='flex  justify-center items-center py-5'>
                {/* <Cards src="/Images/gallery1.jpeg" /> */}
                <DaynamicProducts/>
            </div>
        </>
    )
}

export default Product
