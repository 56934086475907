import React from 'react';

const Contact = ({image}) => {
    return (
        <>
            <section className="bg-white dark:bg-white">
                <div className="grid max-w-screen-xl m-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
                    {/* Contact Form Section */}
                    <div className="px-4 pt-8 md:pb-0 pb-5 lg:col-span-8 lg:pt-16 flex justify-center items-center flex-col">
                        <h1 className="mb-4 text-[25px] tracking-tight leading-none md:text-[70px] text-color2 dark:text-white font-header">
                            Contact Us
                        </h1>
                        <form className="">
                            <div className="space-y-5">
                                <div className="border-b border-color4 pb-7">
                                    <div className="mt-4 grid m-auto gap-y-8">
                                        {/* Full Name Input */}
                                        <div className="md:col-span-full">
                                            <label htmlFor="username" className="block text-[25px] font-body font-medium leading-6 text-color4">
                                                Full Name
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex border-2 border-color2 md:max-w-lg md:w-96">
                                                    <input
                                                        id="username"
                                                        name="username"
                                                        type="text"
                                                        placeholder="Full Name"
                                                        className="block w-full flex-1 text-[20px] border-0 bg-transparent py-2 pl-1 text-color4 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Email Input */}
                                        <div className="col-span-full">
                                            <label htmlFor="email" className="block text-[25px] font-body font-medium leading-6 text-color4">
                                                Email
                                            </label>
                                            <div className="mt-2">
                                                <div className="flex border-2 border-color2">
                                                    <input
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        placeholder="Email"
                                                        className="block flex-1 text-[20px] border-0 bg-transparent py-2 pl-1 text-color4 placeholder:text-gray-400 focus:ring-0 sm:leading-6"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* Message Textarea */}
                                        <div className="col-span-full">
                                            <div className="mt-2">
                                                <textarea
                                                    id="about"
                                                    name="about"
                                                    rows={3}
                                                    className="block w-full flex-1 border-2 text-[20px] border-color2 py-1.5 text-color4 placeholder:text-gray-400  sm:leading-6"
                                                    //    className="block  w-full bg-transparent py-2 pl-1 text-color4 placeholder:text-gray-400 focus:ring-0 sm:leading-6 border-2 text-[25px] border-color2"
                                                    placeholder="Your message"
                                                    defaultValue={''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Submit Button */}
                            <div className="my-6 flex items-center justify-end gap-x-6">
                                <button
                                    type="button"
                                    className="w-full bg-color2 font-body  px-3 py-2 text-white shadow-sm hover:bg-color5 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 text-[26px]"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                    {/* Image Section */}
                    {
                        image ?  <div className=" mt-10 lg:col-span-4 lg:flex h-full lg:h-screen">
                        <img src={image} width="100%" alt="mockup" />
                    </div> : <div className="hidden mt-10 lg:col-span-4 lg:flex h-screen">
                        <img src="/Images/contact.jpg" width="100%" alt="mockup" />
                    </div>
                    }
                   
                </div>
            </section>
        </>
    );
};

export default Contact;
