/* eslint-disable eqeqeq */
import axios from 'axios';
import { List } from 'flowbite-react'
import React, { useEffect, useState } from 'react'

const AboutUs = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const [allDataStore, setAllDataStore] = useState([])
    const GetAllData = async () => {
        try {
            const res = await axios.get(`https://martha-move-up.developer-iu.xyz/api/about_page`)
            console.log(res?.data.aboutPage, 'res');
            setAllDataStore(res?.data?.aboutPage)

        } catch (error) {
            if (error?.response?.data?.status == false) {
                localStorage.clear()
            }
        }
    }
    console.log(allDataStore?.about_content);

    useEffect(() => {

        GetAllData()
    }, [])

    return (
        <>
            <section class=" dark:bg-[#959d89]">
                <h1 className='text-center font-header text-color2 pt-5 text-[30px] md:text-[50px]'>

                    {
                        allDataStore?.about_title ? allDataStore?.about_title : "About Us"
                    }
                </h1>
                <div class="grid max-w-screen-xl items-center justify-center px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div class="mr-auto md:pl-10 place-self-center lg:col-span-7">
                        {
                            allDataStore?.about_content ?
                                <div dangerouslySetInnerHTML={{ __html: allDataStore?.about_content }} />
                                : <>
                                    <p class=" mb-6  text-color4  text-[18px] md:text-[25px] dark:text-white font-body">
                                        "Words can celebrate victory. be hopeful and encouraging. They can prompt us to rethink, reboot, and redirect our course."
                                        <br />
                                        -Ronald A. Rasband</p>
                                    <List className='text-color4 space-y-4 text-[18px] md:text-[23px] font-body'>
                                        <p>

                                            Hello! My name is Martha Sims.              I am the CEO and founder of Martha Moves Up.
                                            I am a member of The Church of Jesus Christ of Latter Day Saints. I know we are known and loved by Heavenly Father and his son Jesus Christ.
                                        </p>
                                        <p>

                                            I love flowers, trees, grass, birds, and mountains. I love to hike and enjoy the beauty of nature. I receive the most inspiration for my life when I am in the mountains.
                                        </p>

                                        <p>


                                            I love meeting new people. Many people like to sleep on plane rides, but I am thrilled when I get to sit by someone who wants to talk
                                            I know what it feels like to suffer from anxiety and depression, and I fight the daily battle of "Am I Good Enough?"
                                        </p>

                                        <p>

                                            Using focus words each month has greatly improved my mental health. For several years, I felt divinely inspired to create focus word cards for my family and friends, and I now I am sharing focus word cards with the world!

                                        </p>
                                        <p>


                                            Life is full of joys and challenges, but through it all, I am grateful for the life I have and the lessons I have learned along the way. I am learning to take small steps each day to reach my goals and to face my challenges head on. Life is an amazing journey and I am determined to keep moving up!
                                            Let's move up together!
                                        </p>

                                    </List>
                                </>
                        }


                    </div>
                    <div class=" m-auto lg:mt-0 relative  lg:col-span-5 lg:flex h-[80vh]">
                        {
                            allDataStore?.about_image ?
                                <img src={allDataStore?.about_image} alt='' className='' />
                                :
                                <img src='/Images/martha_moves_up-8 (2).jpg' alt='' className='' />
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs
