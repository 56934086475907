// import { List } from 'flowbite-react'
import React from 'react'
// import { HiCheckCircle } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';

const Hero = ({text,images}) => {
    const Navigation = useNavigate()
    return (
        <>
            <section class="bg-color5 dark:bg-[#959d89]">
                <div class="grid max-w-screen-xl items-center justify-center px-4 py-8 mx-auto xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div class="mr-auto md:pl-10 place-self-center lg:col-span-7">
                        

{
    text ?
     <div dangerouslySetInnerHTML={{ __html: text }} />
    : <>
    
                        <p class="font-light text-white  text-[20px] md:text-[30px] dark:text-white font-header">Hi,
                        </p>
                        <p class=" font-light text-white  text-[20px] md:text-[30px] dark:text-white font-header">My name is Martha Sims. I am the founder and creator of Martha Moves Up.  I’m so glad you are here. I know what it is like to struggle with depression, anxiety, and to fight the daily battle of “I am good enough?” You are not alone!
                        </p>
                        <div className=' font-light text-white  text-[20px] md:text-[28px] dark:text-white font-header mt-5'>
                            <p>I create products that help you fight back against negative thoughts and give your brain positive things to think about. Using focus words with positive quotes and powerful affirmations has changed my life and improved my mental health.
                            </p>
                        </div>
    </>
}


                        <div className='w-[100px] m-auto mt-5 p-4 text-[20px] md:text-[25px] bg-color2 text-white rounded-2xl flex justify-center'>
                            <button className='text-center' onClick={() => Navigation('/about-us')}>
                                About Us
                            </button>
                        </div>
                    </div>
                    <div class=" m-auto lg:mt-0 mt-3 relative  lg:col-span-5 lg:flex h-[80vh]">
                        {
                            images ? 
                            <img src={images} alt='' className='' /> : 
                        <img src='/Images/real.png' alt='' className='' />
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default Hero