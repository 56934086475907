/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { FaTruck, FaUser, FaDollarSign, FaClipboardList } from 'react-icons/fa'; // Importing icons
import '../../Component/DaynamicProducts/style.css'
const MoreDetailsOrders = () => {
  
  const [orderDetails, setOrderDetails] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const params = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []); 


  const GetAllData = async () => {
    try {
      const res = await axios.get(`https://martha-move-up.developer-iu.xyz/api/client/order/get/${params.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setOrderDetails(res?.data?.order);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    GetAllData();
  }, []);

  if(!orderDetails){
    return(
         <div className='w-full flex justify-center items-center h-[50vh]'>

            <span className="loaderCSSSS"></span>
        </div>
    )
}

  const handleProductChange = (id) => {
    navigate(`/detail/${id}`);
  };

  const {
    TID,
    address,
    city,
    client,
    client_id,
    country,
    created_at,
    email,
    id,
    items,
    payment_source_name,
    phone,
    state,
    status,
    total_price,
    updated_at,
    zip,
    products,
  } = orderDetails;


  return (
    <div className=" px-4 py-8 bg-gray-100 min-h-screen">
      <div className='container mx-auto'>

      <h1 className="text-center font-bold text-4xl mb-8 text-color2 font-header">Order Details</h1>
      <div className="bg-white shadow-lg rounded-lg p-8 border border-gray-200">
        
        {/* Order Info Section */}
        <div className="mb-6 border-b pb-4">
          <h2 className="text-2xl font-semibold mb-4 text-color2 flex items-center font-header">
            <FaClipboardList className="mr-2 text-color2 font-header" /> Order Information
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 font-body gap-4 text-color5">
            <p><span className="font-bold">Order ID:</span> {id}</p>
            <p><span className="font-bold">Transaction ID:</span> {TID}</p>
            <p><span className="font-bold">Status:</span> {status}</p>
            <p><span className="font-bold">Items:</span> {items}</p>
            <p><span className="font-bold">Total Price:</span> ${total_price.toFixed(2)}</p>
            <p><span className="font-bold">Payment Method:</span> {payment_source_name}</p>
            <p><span className="font-bold">Email:</span> {email}</p>
            <p><span className="font-bold">Phone:</span> {phone}</p>
            <p><span className="font-bold">Created At:</span> {new Date(created_at).toLocaleString()}</p>
            <p><span className="font-bold">Updated At:</span> {new Date(updated_at).toLocaleString()}</p>
          </div>
        </div>

        {/* Client Info Section */}
        <div className="mb-6 border-b pb-4">
          <h2 className="text-2xl font-semibold mb-4 text-color2 flex items-center font-header">
            <FaUser className="mr-2 text-color2 font-header" /> Client Information
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 font-body gap-4 text-color5">
            <p><span className="font-bold">Client ID:</span> {client_id}</p>
            <p><span className="font-bold">Name:</span> {client?.first_name} {client?.last_name}</p>
          </div>
        </div>

        {/* Address Section */}
        <div className="mb-6 border-b pb-4">
          <h2 className="text-2xl font-semibold mb-4 text-color2 flex items-center font-header">
            <FaTruck className="mr-2 text-color2 " /> Shipping Address
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 font-body gap-4 text-color5">
            <p><span className="font-bold">Address:</span> {address}</p>
            <p><span className="font-bold">City:</span> {city}</p>
            <p><span className="font-bold">State:</span> {state}</p>
            <p><span className="font-bold">Country:</span> {country}</p>
            <p><span className="font-bold">ZIP Code:</span> {zip}</p>
          </div>
        </div>

        {/* Products Section */}
        <div className="mb-6">
          <h2 className="text-2xl font-semibold mb-4 text-color2 flex items-center font-header">
            <FaDollarSign className="mr-2 text-color2" /> Products
          </h2>
          <div className="space-y-4">
            {products?.map((product) => (
              <div 
                key={product.id} 
                className="p-4 border rounded-md font-body flex justify-between items-center shadow-sm transition duration-300 cursor-pointer text-color5"
              >
                <div>
                  <p><span className="font-bold">Product ID:</span> {product.id}</p>
                  <p><span className="font-bold">Product Name:</span> {product.name}</p>
                  <p><span className="font-bold">Price:</span> ${product.price.toFixed(2)}</p>
                </div>
                <button 
                  onClick={() => handleProductChange(product.product_id)} 
                  className='p-2 bg-color2 font-header text-white rounded-lg hover:bg-color2 transition duration-300'
                >
                  View Product
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default MoreDetailsOrders;

