import Cart from '../../Component/Cart/Cart'

const CartPage = () => {


  return (
    <>
      <Cart />
    </>
  )
}

export default CartPage
