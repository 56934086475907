import React, { useEffect, useRef } from 'react'
import Header from '../Header'
import { useDispatch, useSelector } from 'react-redux';
import { clearCart, removeFromCart } from '../../Redux/cartSlice';
import { useNavigate } from 'react-router-dom';

const Cart = () => {
    const navigate = useNavigate();
    const cartItems = useSelector(state => state.cart.items);
    const dispatch = useDispatch();
    const contentRef = useRef(null); // Ref to the content section

    useEffect(() => {
        if (cartItems.length === 0) {
            console.log("Scrolling to contentRef:", contentRef.current);
            contentRef.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        }
    }, [cartItems]);

    console.log(cartItems, "cartItems");

    const handleRemoveFromCart = (id) => {
        dispatch(removeFromCart(id));
    };

    const handleClearCart = () => {
        dispatch(clearCart());
    };
    const handlechangePage = () => {
        if (localStorage.getItem('token')) {
            navigate('/check')
        } else {
            localStorage.setItem('change', true)
            navigate('/login')

        }
    }
    const subtotal = cartItems.reduce((total, obj) => total + (obj.price * obj.quantity || 0), 0);
    const shippingCost = subtotal >= 99 ? 0 : 8;
    const totalCost = subtotal + shippingCost;

    return (
        <>
            <Header name="Cart" image="/Images/709A9787.jpg" />
            <div className="mx-auto mt-10">
                <div className="sm:flex shadow-md my-10">
                    <div className="w-full sm:w-3/4 bg-white px-10 py-10">
                        <div className="flex justify-between border-b pb-8"
                            ref={contentRef}>
                            <h1 className="text-color2 font-header text-[18px] md:text-[25px]">Shopping Cart</h1>
                            <div>
                                <h2 className="text-[18px] md:text-[25px] text-color2 font-body">{cartItems.length} Items</h2>
                                <p className="cursor-pointer text-red-500 font-body text-[18px] md:text-[25px]" onClick={handleClearCart}>Clear cart</p>
                            </div>
                        </div>

                        {
                            cartItems?.map((e, i) => (
                                <div key={i} className="md:flex items-strech py-8 md:py-10 lg:py-8 border-t border-gray-50">
                                    <div className="md:w-4/12 2xl:w-1/4 w-full">
                                        <img src={e?.image} alt={e?.name} className="h-full object-center object-cover md:block hidden" />
                                        <img src={e?.image} alt={e?.name} className="md:hidden w-full h-full object-center object-cover" />
                                    </div>
                                    <div className="md:pl-3 md:w-8/12 2xl:w-3/4 flex flex-col justify-center">
                                        <p className="text-[16px] md:text-[23px] leading-3 text-color4 md:pt-0 py-4 font-body">SKU : {e?.id}</p>
                                        <div className="flex items-center justify-between w-full">
                                            <p className="text-[16px] md:text-[23px] font-black leading-none text-color2 font-header">{e?.name}</p>
                                        </div>
                                        <p className="text-[16px] md:text-[23px] leading-3 text-color4 py-4 font-body">Cart : {e?.quantity}</p>
                                        <p className="text-[16px] md:text-[23px] leading-3 text-color4 pb-4 font-body">Price : ${e?.price}</p>
                                        <div className="flex items-center justify-between pt-5">
                                            <p onClick={() => handleRemoveFromCart(e?.id)} className="text-[16px] md:text-[23px] leading-3 underline text-red-500 cursor-pointer font-body">Remove</p>
                                            <div>
                                                <p className="text-[18px] md:text-[25px] font-black leading-none text-color3 font-body">Total</p>
                                                <p className="text-[18px] md:text-[25px] font-black font-body leading-none text-color3">${e?.quantity * e?.price}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }

                        <a href="/products" className="flex font-semibold text-[#88917A] text-sm mt-10 font-body">
                            <svg className="fill-current mr-2 text-[#88917A] w-4" viewBox="0 0 448 512">
                                <path d="M134.059 296H436c6.627 0 12-5.373 12-12v-56c0-6.627-5.373-12-12-12H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.569 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296z" />
                            </svg>
                            Continue Shopping
                        </a>
                    </div>

                    <div id="summary" className="w-full sm:w-1/4 md:w-1/2 px-8 py-10">
                        <h1 className="text-[18px] md:text-[25px] border-b pb-8 text-color2 font-header">Order Summary</h1>
                        {cartItems?.map((e) => (
                            <div className="flex justify-between items-center  mt-10 mb-5">
                                <img className='h-14 w-14' src={e?.image} alt='' />
                                <span className="text-[18px] md:text-[25px] uppercase text-color4 font-body">{e?.name}</span>
                                <span className="text-[18px] md:text-[25px] font-body text-color2">${e?.price}</span>
                            </div>
                        ))}
                        <div>
                            <label className="text-[18px] md:text-[25px] font-body inline-block mb-3 text-sm uppercase text-color2">
                                Shipping
                            </label>
                            <select disabled className="block p-2 font-body text-gray-600 w-full text-[18px] md:text-[25px]">
                                <option>{shippingCost > 0 ? `Standard Shipping - $${shippingCost}` : 'Free Shipping'}</option>
                            </select>
                        </div>

                        <div className="border-t mt-8">
                            <div className="flex font-semibold font-body justify-between py-6 text-[18px] md:text-[25px] uppercase text-color2">
                                <span>Total cost</span>
                                <span>${totalCost}</span>
                            </div>
                            <button disabled={cartItems.length === 0} onClick={() => handlechangePage()} className={`${cartItems.length === 0 ? 'cursor-not-allowed' : "cursor-pointer"} bg-color2 font-body hover:bg-color2 py-2 text-[18px] md:text-[25px] text-white uppercase w-full`}>
                                Checkout
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Cart;
