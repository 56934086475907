/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from 'react';
import './styleblog.css';
import Header from '../../Component/Header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Blog = () => {
  const navigate = useNavigate();
  const [allDataStore, setAllDataStore] = useState([]);
  const [allDataStoreStatus, setAllDataStoreStatus] = useState(false);
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);


  const GetAllData = async () => {
    try {
      const res = await axios.get('https://martha-move-up.developer-iu.xyz/api/client/posts');
      setAllDataStore(res.data?.posts?.data || []); // Ensure it's an array
      setAllDataStoreStatus(true)
    } catch (error) {
      console.log(error);
      setAllDataStoreStatus(true)

    }
  };

  useEffect(() => {
    GetAllData();
  }, []);

  if (!allDataStoreStatus) {
    return (
      <>
        <Header name="Blogs" image="/Images/709A9946.jpg" />
        <div className="w-full flex justify-center items-center h-[50vh]">
          <span className="loaderCSSSS"></span>
        </div>
      </>
    );
  }

  return (
    <>
      <Header name="Blogs" image="/Images/709A9946.jpg" />
      <div className="container py-10 m-auto">
        <div className="flex justify-center gap-8 flex-wrap">
          {allDataStore.length > 0 ? (
            allDataStore.map((e, i) => (

              <div
              onClick={() => navigate(`/blog/${e.id}`)}
              key={i}
              className="md:w-3/12 w-11/12 rounded-3xl shadow-2xl relative h-[300px] overflow-hidden cursor-pointer group"
            >
              {/* Image */}
              <img
                src={e?.picture}
                alt="Blog Image"
                className="w-full h-full object-cover transition-transform duration-500 transform group-hover:scale-110 group-hover:-translate-y-2"
              />
            
              {/* Overlay */}
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-70 transition-opacity duration-500 z-10"></div>
            
              {/* Text */}
              <p className="font-body text-[18px] md:text-[23px] text-color1 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                {e?.title?.slice(0, 10)}...
              </p>
            </div>
            

            ))
          ) : (
            <div className="text-center text-color4">
              <p>No blogs available</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Blog;
