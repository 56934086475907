/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useState } from 'react';
// import Header from '../Header';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2'; // Import SweetAlert

// Define the validation schema using Yup
const schema = yup.object().shape({
  name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup.string().email('Invalid email').required('Email is required'),
  phone: yup.string().required('Phone number is required'),
  address: yup.string().required('Address is required'),
  country: yup.string().required('Country is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zip: yup.string().required('Zip code is required'),
});

const Checkout = () => {
  const [payment, setPayment] = useState(0);
  const [loading, setLoading] = useState(false);
  const [promoCodess, setPromoCode] = useState('');
  const [promoCodessFainal, setPromoCodeFainal] = useState('');
  const [promoCodessFainalDiscount, setPromoCodeFainalDiscount] = useState(null);
  const [promoResponse, setPromoResponse] = useState(null)
  const [promoResponseStatus, setPromoResponseStatus] = useState(true)

  const cartItems = useSelector((state) => state.cart.items);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const { register, handleSubmit, setValue, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const cart = cartItems.map((item) => ({
      id: item.id,
      quantity: item.quantity,
    }));
    const finalData = {
      ...data,
      cart,
      promoCode: promoCodessFainal
    };
    try {
      setLoading(true); // Set loading to true before making API call
      const res = await axios.post(`https://martha-move-up.developer-iu.xyz/api/client/proceed/order/${payment == "1" ? "paypal" : "stripe"}`, finalData, {
        headers: {
          Authorization: `Bearer ${token}`, // Attach token in headers
        },
      });
      window.location = res?.data?.payment_url;
    } catch (error) {
      console.error('Error during registration:', error);
    } finally {
      setLoading(false); // Set loading back to false after API call
    }
  };

  const handlePaymentSelect = (paymentMethod) => {
    setPayment(paymentMethod);
    setValue('payment', paymentMethod === 1 ? "Paypal" : "Stripe");
  };

  const handleFormSubmit = () => {
    if (localStorage.getItem("token") == undefined || !localStorage.getItem("token")) {
      navigate('/login');
      return;
    }
    if (!payment) {
      Swal.fire({
        icon: 'warning',
        title: 'Select Payment Method',
        text: 'Please select a payment method before proceeding.',
        confirmButtonText: 'OK'
      });
      return;
    }
    handleSubmit(onSubmit)();
  };


  const handlePromoCheck = async () => {
    if ((localStorage.getItem("token") == undefined || !localStorage.getItem("token"))) {
      navigate('/login');
      return;
    }
    setPromoResponseStatus(false);
    const cart = cartItems.map((item) => ({
      id: item.id,
      quantity: item.quantity,
    }));
    const finalData = { promoCode: promoCodess, cart };
    try {
      const res = await axios.post(`https://martha-move-up.developer-iu.xyz/api/client/coupon_discount`, finalData, {
        headers: {
          Authorization: `Bearer ${token}`, // Attach token in headers
        },
      });
      if (res.data.status) {
        setPromoResponseStatus(true);
        setPromoResponse(res.data.cart_amount.afterDiscount);
        setPromoCodeFainalDiscount(res.data.cart_amount.discountAmount)
        setPromoCodeFainal(promoCodess);
        Swal.fire({
          icon: 'success',
          title: 'Promo Code',
          text: 'Promo applied successfully',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Promo Code',
          text: 'Promo application failed',
        });
      }
    } catch (error) {
      setPromoResponseStatus(true);
    } finally {
      setPromoResponseStatus(true);
    }
  };

  // Calculate total price with conditional shipping
  const cartTotal = cartItems.reduce((total, obj) => total + (obj.price * obj.quantity || 0), 0);
  const adjustedTotal = promoResponse || cartTotal;
  const shippingCost = adjustedTotal >= 100 ? 0 : 8; // Free shipping if adjusted total is $100 or more

  return (
    <>
      <div className="mx-auto mt-10">
        <div className="sm:flex shadow-md my-10">
          <div className="w-full text-[18px] md:text-[25px] sm:w-3/4 bg-white md:px-10 md:py-10 px-2">
            <p className='text-[18px] md:text-[30px] text-color2'>Shipping Address</p>
            <form onSubmit={handleSubmit(onSubmit)} className="overflow-x-auto">
              {/* Address Form Fields */}
              <div className="mt-5">
                <input {...register('name')} placeholder="First Name" className="border font-body p-5 w-full mb-2" />
                {errors.name && <p className="text-red-500 text-xs">{errors.name.message}</p>}

                <input {...register('last_name')} placeholder="Last Name" className="border font-body p-5 w-full mb-2" />
                {errors.last_name && <p className="text-red-500 text-xs">{errors.last_name.message}</p>}

                <input {...register('email')} placeholder="Email" className="border font-body p-5 w-full mb-2" />
                {errors.email && <p className="text-red-500 text-xs">{errors.email.message}</p>}

                <input {...register('phone')} placeholder="Phone Number" className="border font-body p-5 w-full mb-2" />
                {errors.phone && <p className="text-red-500 text-xs">{errors.phone.message}</p>}

                <input {...register('address')} placeholder="Address" className="border font-body p-5 w-full mb-2" />
                {errors.address && <p className="text-red-500 text-xs">{errors.address.message}</p>}

                <input {...register('country')} placeholder="Country" className="border font-body p-5 w-full mb-2" />
                {errors.country && <p className="text-red-500 text-xs">{errors.country.message}</p>}

                <input {...register('city')} placeholder="City" className="border font-body p-5 w-full mb-2" />
                {errors.city && <p className="text-red-500 text-xs">{errors.city.message}</p>}

                <input {...register('state')} placeholder="State" className="border font-body p-5 w-full mb-2" />
                {errors.state && <p className="text-red-500 text-xs">{errors.state.message}</p>}

                <input {...register('zip')} placeholder="Zip Code" className="border font-body p-5 w-full mb-2" />
                {errors.zip && <p className="text-red-500 text-xs">{errors.zip.message}</p>}
              </div>


              {/* Payment Buttons */}
              <div className="flex gap-3 mt-5">
                <button
                  type="button"
                  onClick={() => handlePaymentSelect(1)}
                  className={`bg-color2 text-white py-2 px-4 rounded ${payment === 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={payment === 1}
                >
                  Paypal
                </button>
                <button
                  type="button"
                  onClick={() => handlePaymentSelect(2)}
                  className={`bg-color2 text-white py-2 px-4 rounded ${payment === 2 ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={payment === 2}
                >
                  Credit/ Debit Card
                </button>
              </div>


              <button
                type="button"
                onClick={handleFormSubmit}
                className={`bg-color2 text-[18px] md:text-[25px] text-white uppercase mt-4 border p-4 w-full mb-2 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={loading}
              >
                {loading ? 'Processing...' : 'Order'}
              </button>
            </form>
          </div>

          <div id="summary" className="w-full sm:w-1/4 md:w-1/2 px-8 py-10">
            <h1 className="text-[18px] md:text-[25px] text-color2 border-b pb-8 font-header">Order Summary</h1>
            {cartItems?.map((e) => (
              <div className="flex justify-between items-center  mt-10 mb-5">
                <img className='h-14 w-14' src={e?.image} alt='' />
                <span className="text-[18px] md:text-[25px] uppercase text-color4 font-body">{e?.name}</span>
                <span className="text-[18px] md:text-[25px] font-body text-color2">${e?.price}</span>
              </div>
            ))}
            <div>
              <label className="text-[18px] md:text-[25px] inline-block mb-3 uppercase text-color2 font-body">Shipping</label>
              <select disabled className="block p-2 text-gray-600 w-full text-[18px] md:text-[25px] font-body">
                <option>{shippingCost > 0 ? `Standard Shipping - $${shippingCost}` : 'Free Shipping'}</option>
              </select>
            </div>

            <div className="py-4">
              <label htmlFor="promo" className="text-[18px] md:text-[25px] inline-block mb-3 uppercase text-color2 font-body">
                Promo Code
              </label>
              <input
                onChange={(e) => setPromoCode(e.target.value)}
                type="text"
                id="promo"
                placeholder="Enter your code"
                className="p-2 w-full text-[18px] md:text-[25px] inline-block mb-3 text-color2 font-body"
              />
            </div>
            <button onClick={handlePromoCheck} className="bg-color2 text-[18px] md:text-[25px] text-white uppercase mt-4 border p-2 w-full mb-2">
              {promoResponseStatus ? "Apply" : "Checking..."}
            </button>

            <div className="border-t mt-8">

              <div className="flex justify-between pt-6 uppercase">
                <span className='text-color2 font-body text-[18px] md:text-[25px]'>Shipping cost</span>
                <span className='text-color2 font-body text-[18px] md:text-[25px]'>{(Number(adjustedTotal) + shippingCost) < 100 ? '$8' : "Free"}</span>
              </div>
              <div className="flex justify-between  uppercase">
                <span className='text-color2 font-body text-[18px] md:text-[25px]'>Discount Amount</span>
                <span className='text-color2 font-body text-[18px] md:text-[25px]'>{promoCodessFainalDiscount ? `$${promoCodessFainalDiscount}` : "$0"}</span>
              </div>

              <div className="flex justify-between uppercase">
                <span className='text-color2 font-body text-[18px] md:text-[25px]'>Total cost</span>
                <span className='text-color2 font-body text-[18px] md:text-[25px]'>${Number(adjustedTotal) + shippingCost}</span>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
