import { useEffect } from "react";

const PrivacyPolicy = () => {
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []); 

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-[18px] md:text-[25px] font-semibold mb-4 font-header text-color2">Privacy Policy</h1>

      <h2 className="text-[18px] md:text-[25px] my-4 font-header text-color2">Introduction</h2>
      <p className='font-body text-color4 text-[18px] md:text-[25px]'>
        At Martha Moves Up, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you visit <a href="https://www.marthamovesup.com" className="text-blue-500 underline">www.marthamovesup.com</a> or make a purchase from us.
      </p>

      <h2 className="text-[18px] md:text-[25px] my-4 font-header text-color2">Information We Collect</h2>
      <p className='font-body text-color4 text-[18px] md:text-[25px]'>We collect various types of information to provide and improve our services:</p>
      <ul className="list-none list-inside mb-4 text-[18px] md:text-[25px]">
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Personal Information:</p>
          <p className='font-body text-color4'>When you place an order, create an account, or subscribe to our newsletter, we collect personal information such as your name, email address, shipping address, phone number, and payment details.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Non-Personal Information:</p>
          <p className='font-body text-color4'> We collect non-personal information such as your browser type, IP address, device information, and browsing behavior when you interact with our Site.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Cookies:</p>
          <p className='font-body text-color4'> We use cookies and similar tracking technologies to enhance your experience on our Site. Cookies help us remember your preferences and optimize our Site's performance. You can manage your cookie preferences through your browser settings.</p>
        </li>
      </ul>

      <h2 className="text-xl font-semibold my-4 font-header text-[18px] md:text-[25px] text-color2">How We Use Your Information</h2>
      <p className='font-header text-color4 text-[18px] md:text-[25px]'>We use the information we collect in the following ways:</p>
      <ul className="list-none list-inside mb-4 text-[18px] md:text-[25px]">
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Order Processing:</p>
          <p className='font-body text-color4'>To process and fulfill your orders, including shipping, payment processing, and providing order status updates.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Customer Support:</p>
          <p className='font-body text-color4'> To respond to your inquiries, provide customer service, and resolve any issues.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Marketing:</p>
          <p className='font-body text-color4'> To send you promotional emails, newsletters, and special offers (you can opt out at any time).</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Site Improvement:</p>
          <p className='font-body text-color4'> To analyze usage patterns, improve our Site's performance, and customize your experience.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Legal Compliance:</p>
          <p className='font-body text-color4'> To comply with applicable laws, regulations, and legal processes.</p>
        </li>
      </ul>

      <h2 className="text-[18px] md:text-[25px] font-semibold my-4 font-header text-color2">Information Sharing and Disclosure</h2>
      <p className='font-body text-color4 text-[18px] md:text-[25px]'>
        We respect your privacy and will not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:
      </p>
      <ul className="list-none text-[18px] md:text-[25px] list-inside mb-4">
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Service Providers:</p>
          <p className='font-body text-color4'> We may share your information with trusted third-party service providers who assist us in operating our Site, processing payments, shipping orders, and providing customer support.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Legal Requirements:</p>
          <p className='font-body text-color4'> We may disclose your information if required by law or in response to a valid legal request, such as a subpoena or court order.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Business Transfers:</p>
          <p className='font-body text-color4'> In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the new owners.</p>
        </li>
      </ul>

      <h2 className="text-[18px] md:text-[25px] font-semibold my-4 font-header text-color2">Data Security</h2>
      <p className='font-body text-color4 text-[18px] md:text-[25px]'>
        We take the security of your personal information seriously. We implement industry-standard security measures, such as encryption and secure server hosting, to protect your information from unauthorized access, alteration, or disclosure.
      </p>

      <h2 className="text-[18px] md:text-[25px] font-semibold my-4 font-header text-color2">Your Rights and Choices</h2>
      <p className='font-header text-[18px] md:text-[25px] text-color4'>You have the right to:</p>
      <ul className="list-none text-[18px] md:text-[25px] list-inside mb-4">
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Access and Update:</p>
          <p className='font-body text-color4'> Request access to and update your personal information by logging into your account or contacting us directly.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Opt-Out:</p>
          <p className='font-body text-color4'>Unsubscribe from marketing communications by clicking the "unsubscribe" link in our emails or contacting us.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Delete:</p>
          <p className='font-body text-color4'>Request the deletion of your personal information, subject to certain exceptions as required by law.</p>
        </li>
        <li className="text-color2">
          <p className='font-header font-bold my-4'>Cookies:</p>
          <p className='font-body text-color4'> Manage your cookie preferences through your browser settings.</p>
        </li>
      </ul>

      <h2 className="text-xl font-semibold my-4 font-header text-color2">Third-Party Links</h2>
      <p className='font-body text-[18px] md:text-[25px] text-color4'>
        Our Site may contain links to third-party websites. We are not responsible for the privacy practices or content of these sites. We encourage you to review the privacy policies of any third-party websites you visit.
      </p>

      <h2 className="text-[18px] md:text-[25px] font-semibold my-4 font-header text-color2">Children's Privacy</h2>
      <p className='font-body text-color4 text-[18px] md:text-[25px]'>
        Our Site is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that we have inadvertently collected information from a child under 13, we will take steps to delete that information.
      </p>

      <h2 className="text-[18px] md:text-[25px] font-semibold my-4 font-header text-color2">Changes to This Privacy Policy</h2>
      <p className='font-body text-color4 text-[18px] md:text-[25px]'>
        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. We will notify you of any significant changes by posting the updated policy on our Site and updating the "Last Updated" date at the top of this page.
      </p>

      <h2 className="text-[18px] md:text-[25px] font-semibold my-4 font-header text-color2">Contact Us</h2>
      <p className='font-body text-color4 text-[18px] md:text-[25px]'>
        If you have any questions or concerns about this Privacy Policy or how we handle your personal information, please contact us at:
      </p>
      <p className='font-header text-color2 text-[18px] md:text-[25px]'>
        Email: <a href="mailto:welcome@marthamovesup.com" className="text-blue-500 underline">welcome@marthamovesup.com</a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
