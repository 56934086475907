import React, { useEffect } from 'react';

const ReturnPolicy = () => {
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []); 

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg">
      <h1 className="text-[18px] md:text-[35px] font-bold mb-4 font-header text-color2">Return Policy</h1>
      <h2 className="text-[18px] md:text-[25px] font-semibold mb-2 font-header text-color2">Overview</h2>
      <p className="mb-4 font-body text-color4 text-[18px] md:text-[25px]">
        At Martha Moves Up, we are committed to ensuring your satisfaction with every purchase. If, for any reason, you are not completely happy with your order, we are here to help.
      </p>

      <h2 className="text-[18px] md:text-[25px] font-semibold mb-2 font-header text-color2">Return Eligibility</h2>
      <ul className="list-disc text-[18px] md:text-[25px] list-inside mb-4 font-body text-color4">
        <li><strong className="text-color2">Standard Products:</strong> You may return affirmation card sets, non-customized apparel, and other non-customized products within 30 days of receiving your order.</li>
        <li><strong className="text-color2">Customized Products:</strong> Returns are only accepted if the product is defective or if there was an error in the customization process.</li>
      </ul>

      <h2 className="text-[18px] md:text-[25px] font-semibold mb-2 font-header text-color2">Conditions for Returns</h2>
      <ul className="list-disc text-[18px] md:text-[25px] list-inside mb-4 font-body text-color4">
        <li>The item must be unused, unworn, and in the same condition that you received it.</li>
        <li>It must be in the original packaging.</li>
        <li>A receipt or proof of purchase must be provided.</li>
      </ul>

      <h2 className="text-[18px] md:text-[25px] font-semibold mb-2 font-header text-color2">Non-Returnable Items</h2>
      <ul className="list-disc text-[18px] md:text-[25px] list-inside mb-4 font-body text-color4">
        <li>Final sale items</li>
        <li>Downloadable products</li>
      </ul>

      <h2 className="text-[18px] md:text-[25px] font-semibold mb-2 font-header text-color2">Return Process</h2>
      <ol className="list-decimal text-[18px] md:text-[25px] list-inside mb-4 font-body text-color4">
        <li>Contact Us: Email us at <strong className="text-color2">welcome@marthamovesup.com</strong> with your order number and reason for the return. For defective or incorrect items, please include photos for verification.</li>
        <li>Receive Return Instructions: Our customer service team will provide you with return instructions and a return authorization number.</li>
        <li>Ship Your Item: Pack your item securely and ship it back to us using the address provided in the return instructions. Please note that you are responsible for return shipping costs unless the return is due to our error or a defective product.</li>
        <li>Refunds/Exchanges: Once we receive your returned item, we will inspect it and notify you of the approval or rejection of your refund or exchange. Approved refunds will be processed within 10 business days of receiving the return, and a credit will automatically be applied to your original method of payment. Exchanges will be shipped as soon as possible.</li>
      </ol>

      <h2 className="text-[18px] md:text-[25px] font-semibold mb-2 font-header text-color2">Damaged or Defective Items</h2>
      <p className="mb-4 font-body text-[18px] md:text-[25px] text-color4">
        If you receive a damaged or defective item, please contact us immediately at <strong className="text-color2">welcome@marthamovesup.com</strong>. We will arrange for a replacement or a full refund at no additional cost to you.
      </p>

      <h2 className="text-[18px] md:text-[25px] font-semibold mb-2 font-header text-color2">Late or Missing Refunds</h2>
      <p className="mb-4 font-body text-[18px] md:text-[25px] text-color4">
        If you haven’t received a refund after 10 business days, please first check your bank account again. Then contact your credit card company, as it may take some time before your refund is officially posted. If you’ve done all of this and still have not received your refund, please contact us at <strong className="text-color2">welcome@marthamovesup.com</strong>.
      </p>

      <h2 className="text-[18px] md:text-[25px] font-semibold mb-2 font-header text-color2">Contact Information</h2>
      <p className='font-body text-[18px] md:text-[25px] text-color4'>
        For any questions or concerns about your return, please contact our customer service team at:<br />
        Email: <strong className="text-color2">welcome@marthamovesup.com</strong><br />
        Hours: Monday to Friday, 9:00 AM to 5:00 PM (MST)
      </p>
    </div>
  );
};

export default ReturnPolicy;
