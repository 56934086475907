/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import Slider from '../../Component/Home/Slider'
import CardSec from '../../Component/Home/Card'
import Gallery from '../../Component/Home/Gallery'
import SliderTestimonials from '../../Component/Home/SliderTestimonials'
import Hero from '../../Component/Home/Hero'
import Contact from '../../Component/Home/Contact'
import { useLocation,useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import CookieConsentModal from '../../Component/Home/CookieConsentModal'
import axios from 'axios'

const Home = () => {
    
    const location = useLocation();
    const navigate = useNavigate()
    
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); 

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        
        if (status === 'true') {
            Swal.fire({
                title: "Order",
                text: "Successfull",
                icon: "success"
              });
              setTimeout(()=>{
                navigate('/')
              },1000)
        }
      }, [location.search]);

      
    const [allDataStore, setAllDataStore] = useState([])
    const GetAllData = async () => {
        try {
            const res = await axios.get(`https://martha-move-up.developer-iu.xyz/api/home_page`)
            setAllDataStore(res?.data.homePage)
        } catch (error) {
            if (error?.response?.data?.status == false) {
                localStorage.clear()
            }
        }
    }

    useEffect(() => {

        GetAllData()
    }, [])
      
    return (
        <>
        <CookieConsentModal/>

        <div className='bg-color3 text-white p-3 text-[20px] md:text-[25px] text-center'>
 FREE SHIPPING FOR US ORDERS $99+
        </div>

        <Slider banner={allDataStore?.banner}/>

        <div className='md:p-16 gap-5 flex flex-wrap md:flex-nowrap md:justify-around justify-center items-center'>
            {
                allDataStore?.cards?.length === 3 ?
allDataStore?.cards?.map((e,i)=>{
    return(

        <CardSec key={i} image={e?.image_src} heading={e?.title} para={e?.content}/>
    )
}) : <>
 <CardSec image={"/Images/2.png"} heading="SIMPLE" para={['Focus words to be used in your everyday life.','Neutral colored paper to fit into any home decor.']}/>
                <CardSec image={"/Images/3.png"} heading="POWERFUL" para={['Words to help motivate you and elevate your life.']} />
                <CardSec image={"/Images/1.png"} heading="ELEGANT"  para={['Each card is printed on slightly textured matte paper.', 'Each card set comes with an walnut wood card stand.']}/>
</>
    }
              
        </div>

        <Gallery images={allDataStore?.images} />

        <div className='md:p-16'>
        <SliderTestimonials features={allDataStore?.features} />
        </div>

        <Hero image={allDataStore?.about_image}  text={allDataStore?.about_text} />

        <Contact image={allDataStore?.contact_image}/>
        </>
    )
}

export default Home
