/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { IoMdClose } from "react-icons/io";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Swal from 'sweetalert2';
import imag from '../../709A0038.jpg';
import { useEffect } from 'react';

const CookieConsentModal = () => {
  const [cookies, setCookie] = useCookies(['userConsent']);
  const [showModal, setShowModal] = useState(!cookies.userConsent);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('First Name is required'),
      last_name: Yup.string().required('Last Name is required'),
      email: Yup.string().email('Invalid email format').required('Email is required'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://martha-move-up.developer-iu.xyz/api/save_email', values);
        console.log(response, 'response');
        if (response.data.status) {
          setCookie('userConsent', true, { path: '/' });
          setShowModal(false);
          Swal.fire('Success', 'Thank you for signing up!', 'success');
        } else {
          Swal.fire('Error', 'Something went wrong. Please try again.', 'error');
        }
      } catch (error) {
        Swal.fire('Error', 'Something went wrong. Please try again.', 'error');
      }
    },
  });

  const acceptCookies = () => {
    setShowModal(false);
  };


  const [allDataStore, setAllDataStore] = useState('')

    const GetAllData = async () => {
        try {
            const res = await axios.get(`https://martha-move-up.developer-iu.xyz/api/email/image_url`)
            setAllDataStore(res?.data.url)
        } catch (error) {
          setAllDataStore(imag)
        }
    }

    useEffect(() => {

        GetAllData()
    }, [])



    if (!showModal) {
      return null;
    }
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-[2]">
      <div className="w-4/5 max-w-xl p-7 flex justify-center text-center shadow-lg rounded-2xl">
        <div className="rounded-lg bg-white shadow relative dark:bg-gray-800 dark:border-gray-700">
          <div className="absolute right-2 top-2 cursor-pointer" onClick={acceptCookies}>
            <IoMdClose className='text-white' size={'30px'} />
          </div>

          <div className="w-full rounded-t-2xl">
            <img
              className="w-full object-cover rounded-t-lg"
              src={allDataStore}
              alt="Promotional"
              style={{ height: '200px' }}
            />
          </div>
          <div className="p-5">
            <p className="text-color3 text-[30px] font-bold">Martha Moves Up</p>
            <p className="text-color3 font-bold text-[28px]">GET 10% OFF</p>

            <form onSubmit={formik.handleSubmit} className="mt-4 grid gap-4">
              <div className="grid grid-cols-2 gap-4">
                {/* First Name */}
                <div>
                  <label
                    htmlFor="first_name"
                    className="block text-[22px] font-medium text-color3 text-start"
                  >
                    First Name
                  </label>
                  <input
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder="First Name"
                    className="border-2 border-color3 rounded px-3 py-2 w-full text-[22px] focus:outline-none"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.first_name && formik.errors.first_name ? (
                    <p className="text-red-500 text-start text-[20px]">{formik.errors.first_name}</p>
                  ) : null}
                </div>

                {/* Last Name */}
                <div>
                  <label
                    htmlFor="last_name"
                    className="block text-[22px] font-medium text-color3 text-start"
                  >
                    Last Name
                  </label>
                  <input
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder="Last Name"
                    className="border-2 border-color3 rounded px-3 py-2 w-full text-[22px] focus:outline-none"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.last_name && formik.errors.last_name ? (
                    <p className="text-red-500 text-[20px]">{formik.errors.last_name}</p>
                  ) : null}
                </div>
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-[22px] font-medium text-color3 text-start"
                >
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  className="border-2 border-color3 rounded px-3 py-2 w-full text-[22px] focus:outline-none"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.email && formik.errors.email ? (
                  <p className="text-red-500 text-[20px]">{formik.errors.email}</p>
                ) : null}
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className="w-full bg-color2 mt-4 px-4 py-2 text-white rounded shadow-md hover:bg-color5 focus:outline-none text-[20px] font-semibold"
              >
                Elevate Your Life Now!
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentModal;
