
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './Layout/Layout';
import Home from './Page/Home/Home';
import Product from './Page/Product/Product';
import Detail from './Page/Detail/Detail';
import CartPage from './Page/Cart/CartPage';
import CheckoutPage from './Page/Checkout/CheckoutPage';
import AboutUs from './Page/About-us/AboutUs';
import SignUp from './Page/Auth/SignUp';
import Login from './Page/Auth/Login';
import Faqs from './Page/Faqs/Faqs';
import PrivacyPolicy from './Page/PrivacyPolicy/PrivacyPolicy';
import ReturnPolicy from './Page/Return-Policy/ReturnPolicy';
import Orders from './Page/Orders/Orders';
import MoreDetialsOrders from './Page/Orders/MoreDetialsOrders';
import Blog from './Page/Blog/Blog';
import BlogDetail from './Page/Blog/BlogDetail';
// import { useEffect } from 'react';
import './App.css'

function App() {

  return (
    <>
      <BrowserRouter>
          <Layout>
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/:id" element={<Home />} />
            <Route exact path="/products" element={<Product />} />
            <Route exact path="/detail/:id" element={<Detail />} />
            <Route exact path="/cart" element={<CartPage />} />
            <Route exact path="/check" element={<CheckoutPage />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/faqs" element={<Faqs />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/return-policy" element={<ReturnPolicy />} />
            <Route exact path="/orders" element={<Orders />} />
            <Route exact path="/orders/:id" element={<MoreDetialsOrders />} />
            <Route exact path="/blog" element={<Blog />} />
            <Route exact path="/blog/:id" element={<BlogDetail />} />
        </Routes>
          </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
