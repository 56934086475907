/* eslint-disable eqeqeq */
import React from 'react'

const Gallery = ({ images }) => {
    console.log(images, 'images');

    return (
        <>
            {
                images?.length == 5 ?

                    <div class="py-2 grid grid-cols-1 md:grid-cols-5 gap-0">
                        {
                            images?.map((e, i) => {
                                return (
                                    <div key={i}>
                                        <img class="h-[40vh] w-full rounded-none" src={e?.path} alt={e?.home_page_id} />
                                    </div>
                                )
                            })
                        }

                    </div>
                    :

                    <div class="py-2 grid grid-cols-1 md:grid-cols-5 gap-0">
                        <div>
                            <img class="h-[40vh] w-full rounded-none" src="/Images/g1.jpg" alt="" />
                        </div>
                        <div>
                            <img class="h-[40vh] w-full rounded-none" src="/Images/g2.jpg" alt="" />
                        </div>
                        <div>
                            <img class="h-[40vh] w-full rounded-none" src="/Images/g4.jpg" alt="" />
                        </div>
                        <div>
                            <img class="h-[40vh] w-full rounded-none" src="/Images/g3.jpg" alt="" />
                        </div>

                        <div>
                            <img class="h-[40vh] w-full rounded-none" src="/Images/g5.jpg" alt="" />
                        </div>
                    </div>
            }

        </>
    )
}

export default Gallery