import React, { useEffect } from 'react'
import Details from '../../Component/Detail/Details'
// import Releated from '../../Component/Detail/Releated'

const Detail = () => {
    
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []); 
    
    return (
        <>
            <Details />
        </>
    )
}

export default Detail
