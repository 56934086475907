import { useEffect, useState } from 'react';

const faqs = [
  {
    question: "What are affirmation cards?",
    answer: "Affirmation cards are cards that contain positive statements or affirmations designed to encourage and inspire you. They can be used daily to promote positive thinking and boost your confidence and motivation. Our set includes 12 positive focus word cards with 5 cards to complement each word. Use the words and cards daily, monthly, or as needed in your life."
  },
  {
    question: "What types of apparel and products do you offer?",
    answer: "We offer a range of apparel, including t-shirts, sweatshirts, hats, crossover bags, and a water bottle, all featuring uplifting and positive focus words to help you feel empowered and positive every day. Most of our products can be customized by allowing you to choose a focus word to be printed on the product of your choice. The crossover bag does not have a customization option."
  },
  {
    question: "How do I place an order?",
    answer: "To place an order, simply browse our products, add your desired items to your cart, and proceed to checkout. Follow the on-screen instructions to complete your purchase."
  },
  {
    question: "Can I customize my affirmation cards or apparel?",
    answer: "Yes, we offer customization options for apparel and accessories. You can choose a focus word meaningful to you to have printed or embroidered on the product you choose."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept all major credit cards, including Visa, MasterCard, American Express, and PayPal."
  },
  {
    question: "Do you ship internationally?",
    answer: "At this time, we only ship within the United States. We do not offer international shipping."
  },
  {
    question: "How long will it take to receive my order?",
    answer: "Orders are typically processed within 2-3 business days. Delivery times vary based on your location and the shipping method selected. Estimated delivery times will be provided at checkout, usually 7 to 10 days."
  },
  {
    question: "How can I track my order?",
    answer: "Once your order has been shipped, you will receive a confirmation email with a tracking number. Use this number to track your order on our website or the carrier’s website."
  },
  {
    question: "What is your return policy?",
    answer: "We want you to be completely satisfied with your purchase. If you are not happy with your order, you can return it within 30 days of receipt for a full refund or exchange. Please refer to our Return Policy for more details."
  },
  {
    question: "How do I return or exchange an item?",
    answer: "To initiate a return or exchange, please contact our customer service team with your order number and reason for return. We will provide you with instructions on how to proceed. You may contact customer service at welcome@marthamovesup.com."
  },
  {
    question: "Who pays for return shipping?",
    answer: "Customers are responsible for return shipping costs unless the item is defective or incorrect. In such cases, we will cover the return shipping costs."
  },
  {
    question: "How can I contact customer support?",
    answer: "If you have any questions or need assistance, please contact our customer support team via email at welcome@marthamovesup.com. We are here to help!"
  },
  {
    question: "What are your customer support hours?",
    answer: "Our customer support team is available Monday through Friday, from 9:00 AM to 5:00 PM (EST). Usually, we will respond quickly, but please allow 24-48 hours for a reply."
  }
];

const Faqs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []); 


  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-[18px] md:text-[25px] font-semibold mb-4 font-header text-color2">Frequently Asked Questions (FAQs)</h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-300">
            <button
              onClick={() => toggleFAQ(index)}
              className="flex text-[18px] md:text-[25px] justify-between items-center w-full p-4 text-left bg-gray-100 hover:bg-gray-200 focus:outline-none"
            >
              <span className=" font-header text-color2">{faq.question}</span>
              <span className='text-color2'>{openIndex === index ? '-' : '+'}</span>
            </button>
            {openIndex === index && (
              <div className="p-4 text-[16px] md:text-[23px] font-body text-color4">
                {faq.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Faqs;
