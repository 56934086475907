/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../../Component/DaynamicProducts/style.css'

const Orders = () => {
  
  const [allDataStore, setAllDataStore] = useState();
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}, []); 


  const GetAllData = async () => {
    try {
      const res = await axios.get(`https://martha-move-up.developer-iu.xyz/api/client/orders`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAllDataStore(res?.data?.orders?.data);
      console.log(res, 'res');
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    GetAllData();
  }, []);

  const handleViewDetails = (orderId) => {
    navigate(`/orders/${orderId}`);
  };

  if (!allDataStore) {
    return (
      <div className='w-full flex justify-center items-center h-[50vh]'>

        <span className="loaderCSSSS"></span>
      </div>
    )
  }
  return (
    <div className="bg-gray-100 min-h-screen py-6">
      <h1 className="text-center font-bold text-4xl text-color2 mb-8 font-header">Orders</h1>
      <div className="container mx-auto px-4 py-3 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {allDataStore.map((order) => (
          <div key={order.id} className="bg-white shadow-lg rounded-lg overflow-hidden border border-gray-200 transition-transform transform hover:scale-105">
            <div className="p-6 font-body">
              <h2 className="text-xl font-semibold text-color5 mb-2 ">Order ID: {order.id}</h2>
              <p className="text-color5"><span className="font-semibold">Client ID:</span> {order.client_id}</p>
              <p className="text-color5"><span className="font-semibold">Items:</span> {order.items}</p>
              <p className="text-color5"><span className="font-semibold">Payment Method:</span> {order.payment_source_name}</p>
              <p className="text-color5"><span className="font-semibold">Status:</span> {order.status}</p>
              <p className="text-color5"><span className="font-semibold">Total Price:</span> ${order.total_price.toFixed(2)}</p>
            </div>
            <button
              onClick={() => handleViewDetails(order.id)}
              className="w-full font-header bg-color2 text-white font-semibold py-2 hover:bg-color2 transition duration-300"
            >
              View More Details
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Orders;
